<template>
  <div class="container">
    <div class="handle-box">
      <el-button type="success" icon="el-icon-plus" @click="addAll">
        添加
      </el-button>
      <el-button icon="el-icon-refresh" @click="onRefresh">刷新</el-button>
    </div>
    <el-table
      v-loading="listLoading"
      :data="list"
      element-loading-text="正在加载 ..."
      border
      fit
      stripe
      highlight-current-row
      row-key="id"
      :tree-props="{ children: 'childrenList', hasChildren: 'hasChildren' }"
      @row-dblclick="onEdit"
    >
      <el-table-column label="菜单标题">
        <template slot-scope="scope">
          <i class="el-icon-menu"></i>
          <span style="margin-left: 10px">{{ scope.row.title }}</span>
        </template>
      </el-table-column>
      <el-table-column label="菜单编号" prop="code" />
      <el-table-column label="菜单图标" prop="icon" />
      <el-table-column label="菜单URL" prop="url" />
      <el-table-column label="序号" prop="xh" />
      <el-table-column label="是否显示在首页TAB" prop="isTab">
        <template slot-scope="scope">
          <font v-if="scope.row.isTab === 1" color="green" size="2">显示</font>
          <font v-else size="2">隐藏</font>
        </template>
      </el-table-column>
      <el-table-column label="是否可用" prop="isActive">
        <template slot-scope="scope">
          <el-switch
            v-model="scope.row.isActive"
            active-color="#13ce66"
            inactive-color="#ff4949"
            :active-value="1"
            :inactive-value="0"
            @change="changeSwitch($event, scope.row, scope.$index)"
          >
          </el-switch>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="220" align="center">
        <template slot-scope="scope">
          <el-button type="success" size="small" @click="onAdd(scope.row)">
            添加
          </el-button>
          <el-button type="primary" size="small" @click="onEdit(scope.row)">
            编辑
          </el-button>
          <el-button type="danger" size="small" @click="onDel(scope.row)">
            删除
          </el-button>
        </template>
      </el-table-column>
    </el-table>

    <!-- 编辑弹出框 -->
    <el-dialog
      title="编辑信息"
      width="780px"
      :visible.sync="selectDlgShow"
      :close-on-click-modal="false"
    >
      <div
        v-loading="dlgLoading"
        class="dialog-body"
        element-loading-text="正在保存..."
      >
        <el-form ref="ruleForm" :model="form" label-width="150px">
          <el-form-item label="上级菜单" prop="pid">
            <el-cascader
              v-model="form.pid"
              :options="pmenuList"
              clearable
              :show-all-levels="false"
              :props="{
                value: 'id',
                label: 'title',
                children: 'childrenList',
                emitPath: false,
                checkStrictly: true
              }"
              placeholder="请选择上级部门"
            >
            </el-cascader>
            <span class="demo-tip">不填默认未顶级菜单</span>
          </el-form-item>
          <el-form-item label="菜单标题" prop="title" required>
            <el-input v-model="form.title" clearable />
          </el-form-item>
          <el-form-item label="菜单编号" prop="code" required>
            <el-input v-model="form.code" clearable></el-input>
          </el-form-item>
          <el-form-item label="菜单图标" prop="icon">
            <el-input v-model="form.icon" clearable></el-input>
          </el-form-item>
          <el-form-item label="菜单URL" prop="url">
            <el-input v-model="form.url" type="text" clearable />
          </el-form-item>
          <el-form-item label="序号" prop="xh">
            <el-input v-model="form.xh" type="number" clearable />
          </el-form-item>
          <el-form-item label="是否显示在首页TAB" prop="isTab">
            <el-select v-model="form.isTab" placeholder="请选择">
              <el-option label="显示" :value="1"></el-option>
              <el-option label="隐藏" :value="0"></el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button type="danger" plain @click="selectDlgShow = false">
          关 闭
        </el-button>
        <el-button size="small" @click="resetForm('ruleForm')">重 置</el-button>
        <el-button size="small" type="primary" @click="submitForm('ruleForm')">
          保 存
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  getMenuList,
  saveMenu,
  delMenu,
  updateIsActive
} from '@/api/admin/menu';

export default {
  name: 'Menu',
  data() {
    return {
      form: {
        id: '',
        pid: '',
        title: '',
        code: '',
        icon: '',
        url: '',
        xh: '',
        isTab: ''
      },
      list: [],
      pmenuList: [],
      listLoading: true,
      dlgLoading: false,
      selectDlgShow: false,
      multipleSelection: []
    };
  },
  created() {
    this.fetchData();
  },
  methods: {
    loadMenuList() {
      this.pmenuList = [];
      getMenuList().then(response => {
        this.pmenuList = response.data;
      });
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    onRefresh() {
      this.fetchData();
    },
    onDel(row) {
      this.$confirm('您是否删除该菜单及所有子菜单, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        delMenu({ id: row.id }).then(resp => {
          this.$message({
            type: 'success',
            message: resp.msg
          });
          this.fetchData();
        });
      });
    },
    fetchData() {
      this.listLoading = true;
      getMenuList(this.pageInfo).then(response => {
        this.list = response.data.map(mapper);
        this.listLoading = false;
      });

      function mapper(item) {
        item.childrenList?.sort((a, b) => a.xh - b.xh);
        item.childrenList?.map(mapper);
        return item;
      }
    },
    changeSwitch(data, b, index) {
      //开关切换
      var id = b.id;
      var isActive = b.isActive;
      updateIsActive({ id: id, status: isActive }).then(resp => {
        if (resp.code == 200) {
          this.$message({
            message: '状态修改成功',
            type: 'success'
          });
        } else {
          this.$message.error(resp.msg);
        }
      });
    },
    addAll() {
      this.loadMenuList();
      this.form = {
        id: '',
        pid: '',
        title: '',
        code: '',
        icon: '',
        url: '',
        xh: '',
        isTab: '',
        isActive: 1
      };
      this.selectDlgShow = true;
    },
    onAdd(row) {
      this.loadMenuList();
      this.form = {
        id: '',
        pid: row.id,
        title: '',
        code: '',
        icon: '',
        url: '',
        xh: '',
        isTab: '',
        isActive: 1
      };
      this.selectDlgShow = true;
    },
    onEdit(row) {
      this.loadMenuList();
      this.form = {
        id: row.id,
        pid: row.pid,
        title: row.title,
        code: row.code,
        icon: row.icon,
        url: row.url,
        xh: row.xh,
        isTab: row.isTab
      };
      this.selectDlgShow = true;
    },
    submitForm(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          this.save();
        } else {
          return false;
        }
      });
    },
    save() {
      this.dlgLoading = true;
      saveMenu(this.form)
        .then(resp => {
          if (resp.code == 200) {
            this.$message({
              message: '保存成功',
              type: 'success'
            });
            this.selectDlgShow = false;
            this.dlgLoading = false;
            this.fetchData();
          } else {
            this.$message.error('保存失败');
          }
        })
        .catch(() => {
          this.dlgLoading = false;
        });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    }
  }
};
</script>

<style scoped>
.handle-box {
  margin-bottom: 20px;
}
.del-dialog-cnt {
  font-size: 16px;
  text-align: center;
}
</style>
